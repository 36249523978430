import logo from './logo.svg';
import './App.css';

document.body.style = 'background: #282c34;';

function Title({ name }) {
  return (
    <text className="Company-name">{name}</text>
  )
}

function App() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="Read. Share. Follow." />
      <p>
        <Title name="Read. Share. Follow." />
      </p>
      <text>
          Coming soon...
      </text>
    </header>
  )
}

export default App;